// VUE app bootstrap for browser
import { createApp } from 'vue'
import App from './App.vue'
import context from './context'
import router from '@/router'
import netteBaseModule from 'nette-base-module'

// create vue app instance
context.vueApp = createApp(App)

// init router
context.vueApp.use(router)

// init nette modules
context.vueApp.use(netteBaseModule, context)

// mount app to dom
context.vueApp.mount('#' + context.rootElementId)
